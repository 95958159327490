import React from "react";
import Header from "../components/header";
import Team from "../components/team";

export default function About() {
  return (
    <>
      {/* HEADER */}
      <Header page="about" />

      <div>
        <h1 className="blue mt-5 text-center mb-4">
          ABOUT <span className="red">KTI</span>
        </h1>
        <div className="row">
          <div className="col-md-7">
            <div style={{ paddingInline: "30px" }}>
              <p>
                Kyadondo Technical Institute (KTI) is a not for profit Private
                Business & Technical Training Institute located in
                Busabala-Makindye Kampala. It is established and fully
                registered by the Ministry of Education and Sports Registration
                no. ME/VOC/421, accredited by the Directorate of Industrial
                Training. <br />
                The Institute also collaborates with reputable academic
                institutions in the country like Makerere University Kampala,
                Islamic University in Uganda, and others. The Institute is a
                National Examining Centre for UBTEB, DIT & ECD. The Institute
                Centre Numbers are BTV529, UBT416, UBB272, DIT216, and ECD422.
                It offers a variety of courses in Polytechnic Certificates (PLE
                leavers), National Certificates Courses (UCE leavers or its
                equivalent), and National Diploma Courses (UACE leavers or its
                equivalent).
              </p>

              {/* MISSION */}
              <h3 className="blue mt-4">
                Our <span className="red">Mission</span>
              </h3>
              <p>
                At Kyadondo Technical Institute, our mission is ”To prepare
                students to create their own future through lifelong learning
                based on community values”. We believe in fostering a learning
                environment that not only imparts practical skills but also
                instills a sense of community responsibility. Our commitment to
                quality education is reflected in our partnership with reputable
                examination bodies, including KTIEC, UBTEB, and DIT, providing a
                robust evaluation process that enhances the credibility of our
                programs. Embark on a journey of skill acquisition and personal
                growth at our Department of Non-Formal Programs. Your path to a
                brighter future, rooted in community values, starts here.
              </p>

              {/* VISION */}
              <h3 className="blue mt-4">
                <span className="red">Our</span> Vision
              </h3>
              <p>
                At Kyadondo Technical Institute, our mission is ”To prepare
                students to create their own future through lifelong learning
                based on community values”. We believe in fostering a learning
                environment that not only imparts practical skills but also
                instills a sense of community responsibility. Our commitment to
                quality education is reflected in our partnership with reputable
                examination bodies, including KTIEC, UBTEB, and DIT, providing a
                robust evaluation process that enhances the credibility of our
                programs. Embark on a journey of skill acquisition and personal
                growth at our Department of Non-Formal Programs. Your path to a
                brighter future, rooted in community values, starts here.
              </p>
            </div>
          </div>
          <div className="col-md-5 d-flex justify-content-center align-items-center">
            <img
              src="images/building.jpg"
              alt="..."
              style={{
                borderRadius: "15px",
                marginRight: "10px",
              }}
            />
          </div>
        </div>

        <div className="make text-white">
          <div className="container" style={{ paddingInline: "20px" }}>
            <div className="row">
              <div className="col-md-12">
                {/* CORE VALUES */}
                <h3 className="mb-4">
                  CORE <span className="yellow">VALUES</span>
                </h3>
              </div>
            </div>
            <div className="row">
              <p>
                These principles remain the cornerstone of our interactions with
                students, permeating the messages conveyed in assemblies, and
                forming the essence of our reward and penalty systems at the
                Institute. They embody the qualities we aspire for in the
                Kyadondonian community.
              </p>

              <ul>
                <li>
                  <strong className="yellow">Courage:</strong> The capacity to
                  confront fear, pain, danger, uncertainty, or intimidation. It
                  involves taking calculated risks in the pursuit of virtue.
                </li>
                <li>
                  <strong className="yellow">Respect:</strong> A positive regard
                  for individuals, groups, entities, or qualities, leading to
                  fair and just actions. This extends to self-respect, where one
                  maintains personal values without compromising for external
                  approval.
                </li>
                <li>
                  <strong className="yellow">Integrity:</strong> Demonstrating
                  consistency and authenticity, aligning actions with one's
                  values and beliefs.
                </li>
                <li>
                  <strong className="yellow">Kindness:</strong> Displaying
                  consideration for others and engaging in selfless actions for
                  their well-being.
                </li>
                <li>
                  <strong className="yellow">Responsibility:</strong> Being
                  reliable, dependable, and accountable for one's behavior,
                  fulfilling duties, and recognizing the obligation to serve the
                  communities in which we operate.
                </li>
                <li>
                  <strong className="yellow">Equity:</strong> Ensuring services
                  are provided impartially and equally to all individuals and
                  groups, considering special needs.
                </li>
                <li>
                  <strong className="yellow">Quality:</strong> Regularly
                  assessing our performance to enhance the efficiency of our
                  services through continuous improvement.
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Our Team */}
        <Team />

        {/* ACADEMIC BOARD */}
        <div className="row mt-5">
          <div className="col-md-5 d-flex justify-content-center align-items-center">
            <img
              src="images/board.jpg"
              alt="..."
              className="pr-0 mr-0"
              style={{
                borderRadius: "15px",
                marginLeft: "10px",
                height: "80%",
              }}
            />
          </div>

          <div className="col">
            <h3 className="blue text-center mt-3 mb-4">
              ACADEMIC <span className="red">BOARD</span>{" "}
            </h3>
            <div style={{ paddingInline: "20px" }}>
              <p>
                The KTI academic board is an academic organ of the Institution
                mandated to advise the Governing Council on all academic matters
                and questions affecting the educational policy of KTI. This
                organ consists of:
              </p>
              <ul>
                <li>The Principal</li>
                <li>Academic Registrar</li>
                <li>Heads of Departments</li>
                <li>Students' Representative (1)</li>
              </ul>

              <p className="mt-3">This board is mandated to:</p>
              <ol>
                <li>
                  Oversee the quality assurance of the academic work of KTI.
                </li>
                <li>Promote excellence in teaching and learning.</li>
                <li>
                  Facilitate free intellectual inquiry and ensure academic
                  integrity.
                </li>
                <li>
                  Ensure a culture of scholarship is developed and nurtured
                  within KTI.
                </li>
                <li>
                  Consider and make decisions on all aspects of the development
                  and accreditation or re-accreditation of higher education
                  courses, the admission of students, teaching, assessment and
                  requirements for graduation, prizes, awards, and scholarships.
                </li>
                <li>
                  Formulate, coordinate, and review academic policy, procedures,
                  and guidelines within KTI.
                </li>
                <li>
                  Advise on the academic aspects of KTI's strategic,
                  operational, and risk management plans and to foster discourse
                  on issues related to higher education and KTI's Vision and
                  Goals.
                </li>
                <li>
                  Consider and recommend on any matter referred to the Academic
                  Board by the Board of Directors.
                </li>
                <li>
                  In addition to such matters as are specifically referred to
                  the Academic Board, the Academic Board may generate reports
                  and recommendations to the Board of Directors, including
                  recommending new courses for development.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className="container-fluid" style={{ paddingInline: "20px" }}>
          <h3 className="blue mt-3">GOVERNANCE</h3>
          The Governing Council (GC) is the top Management organ of Institute.
          This council undertakes its mandate in accordance with the BTVET Act
          of 2008. It is headed by the able Chairman of great reputation as well
          as an academia and draws its membership from outstanding members of
          the Ugandan society. Specifically, the GC sets out internal policies
          and development priorities for the institute and approves the budget
          for both recurrent and development expenditure. The GC is also
          responsible for the implementation of government policies, approving
          the Institutional Curriculum, ensuring safety of students and staff,
          overseeing all other institutional programs as well as steering growth
          and development of the entire school.
        </div>
      </div>
    </>
  );
}
