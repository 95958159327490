import React, { useEffect } from "react";
import { useLoading } from "../loading_context";
import MobileHeader from "./mobile_header";

export default function Header({ page }) {
  const formatTitle = (title) => {
    // Capitalize each word in the title
    return title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const title_formatted = formatTitle(
    page || "Kyadondo Technical Institute - KTI"
  );

  // const { setLoading } = useLoading();

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 1500);
  // }, []);

  // CHECK IF SCREEN SIZE IS MOBILE
  const isMobile = window.matchMedia("(max-width: 720px)").matches;

  if(isMobile){
    return (
      <MobileHeader page={page} />
    )
  }

  return (
    <>
      <title>{title_formatted}</title>
      <header className="d-none d-md-block">
        {/* TOP HEADER */}
        <div className="top-header">
          <div className="row">
            <div className="col-3 d-flex justify-content-center">
              
              {/* FACEBOOK */}
              <a
                href="http://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/fb.svg" alt="..." />
              </a>

              {/* TWITTER */}
              <a
                href="http://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/x.webp" alt="..." />
              </a>

              {/* INSTAGRAM */}
              <a
                href="http://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/ig.svg" alt="..." />
              </a>

              {/* WHATSAPP */}
              <a
                href="http://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/wa.webp" alt="..." />
              </a>

              {/* YOUTUBE */}
              <a
                href="http://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="images/yt.svg" alt="..." />
              </a>
            </div>

            {/* CONTACT DETAILS */}
            <div className="col-9 d-flex justify-content-end mr-5 pr-5 ">
              {/* Location */}
              <span className="d-none d-md-block">
                <a href="#">
                  <img src="images/location.svg" alt="..." /> Busabala-Makindye,
                  Kampala Uganda
                </a>
              </span>

              {/* phone */}
              <span>
                <a href="tel:+256392548317">
                  <img src="images/phone.svg" alt="..." /> +256392548317 |
                  +256702081313
                </a>
              </span>

              {/* email */}
              <span className="padd-right">
                <a href="mailto:info@kti.ac.ug">
                  <img src="images/email.svg" alt="..." /> info@kti.ac.ug
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="row header">
          {/* LOGO */}
          <div className="col-md-3">
            <div className="logo">
              <a href="/">
                <img className="img-fluid" src="images/logo.png" alt="#" />
              </a>
            </div>
          </div>

          {/* NAV MENU ITEMS */}
          <div className="col-md-7">
            <div className="menu-area">
              <nav className="main-menu ">
                <ul className="menu-area-main">
                  <li className={page === "home" ? "active" : ""}>
                    <a href="/">Home</a>
                  </li>
                  <li className={page === "about" ? "active" : ""}>
                    <a href="/about">About</a>
                  </li>
                  <li className={page === "courses" ? "active" : ""}>
                    <a href="/courses">Our Courses </a>
                  </li>
                  <li className={page === "admission" ? "active" : ""}>
                    <a href="/admission">Admission</a>
                  </li>
                  <li className={page === "fees_structure" ? "active" : ""}>
                    <a href="fees_structure.pdf" target={"_blank"}>Fees Structure</a>
                  </li>
                  <li className={page === "contact" ? "active" : ""}>
                    <a href="/contact">Contact</a>
                  </li>
                  <li>
                    <a href="https://portal.kti.ac.ug" target="_blank">Portal</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {/* APPLY BUTTON */}
          <div className="col-md-2 d-flex align-items-center justify-content-center">
            <a href="https://admissions.kti.ac.ug">
              <button className="btn btn-warning rounded-pill px-4">
                APPLY NOW
              </button>
            </a>
          </div>
        </div>
      </header>
    </>
  );
}
