import Footer from './components/footer';
import Preloader from './components/preloader';
import { useLoading } from './loading_context';
import NotFound from './pages/not_found';
import Contact from './pages/contact';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Homepage from './pages/homepage';
import About from './pages/about';
import Courses from './pages/courses';
import ScrollToTop from './components/scroll_to_top';
import Admission from './pages/admission';

function App() {

  const { isLoading } = useLoading();

  return (
    <div>
      {isLoading && <Preloader />}
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Homepage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/courses" element={<Courses />} />
          <Route path="/admission" element={<Admission />} />
        </Routes>
        <Footer />
        <ScrollToTop />

      </BrowserRouter>
    </div>
  );
}

export default App;
