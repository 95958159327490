import React from "react";
// import staff images
import micheal from "../assets/staff/micheal1.jpg";
import allan from "../assets/staff/allan.jpg";
import opio from "../assets/staff/opio.jpg";
import sophia from "../assets/staff/sophia.jpg";
import nkome from "../assets/staff/NKOME.jpg";
import navubya from "../assets/staff/navubya.jpg";
import lasito from "../assets/staff/lasito.jpg";
import sam from "../assets/staff/sam.jpg";
import grace from "../assets/staff/grace.jpg";
import vicent from "../assets/staff/vicent.jpg";


export default function Team() {
  const team = [
    {
      name: "Mr. Sserwanja Asadu",
      position: "SENIOR ADMINISTRATIVE SECRETARY",
      email: "sserwanjaasadu@gmail.com",
      phone: "0782954910 | 0702081313",
      image: "images/director1.jpg",
    },
    {
      name: "Mr. Kanaaba Deus",
      position: "PRINCIPAL",
      email: "deuskans@gmail.com",
      phone: "+256758156928",
      image: "images/principal.jpg",
    },
    {
      name: "Navubya Phionah",
      position: "HEAD OF SECTION, Cosmetology & HAIRDRESSING",
      email: "fifiwonders07@gmail.com",
      phone: "0704559530",
      image: navubya,
    },
    {
      name: "Ssekachwa Michael",
      position: "Head of Section, Business & Management",
      email: "ssekachwam@gmail.com",
      phone: "0703016867 / 0785639721",
      image: micheal,
    },

    {
      name: "Nakalembe Sophia ",
      position: "Head of section, catering ",
      email: "nakalembesophy@gmail.com ",
      phone: "0704630724/0778543471",
      image: sophia,
    },

    {
      name: "Luswabi David",
      position: "Head of section, Electrical ",
      email: "luswabidavid1@gmail.com",
      phone: "0789217292 | 0758424264",
      image: "images/luswabi.jpg",
    },
    {
      name: "OPIO NIMROD",
      position: "HEAD OF SECTION, FASHION AND GARMENT DESIGN ",
      email: "nimirodio@gmail.com",
      phone: "0776722184/0759526441",
      image: opio,
    },
    {
      name: "NKOME RAMATHAN ",
      position: "HEAD OF SECTION, JOURNALISM",
      email: "nkomerama8@gmail.com ",
      phone: "0756196028",
      image: nkome,
    },
    {
      name: "Najjemba Zamu Musoke",
      position: "HOD Design and Technology studies",
      email: "zamiekin1995@gmail.com",
      phone: "0759818701",
      image: "images/zamu.jpg",
    },
    {
      name: "SSEMULIGO VICENT BENJAMIN",
      position: "HEAD OF DEPARTMENT BUSINESS",
      email: "ssemuligovicentbenjamin@gmail.com",
      phone: "0701618528/0781980792",
      image: "images/semulingo.jpg", 
  },

    {
      name: "ALLAN GEORGE OPIO",
      position: "HEAD OF SECTION, PLUMBING AND WATER ",
      email: "allangeorgeopio@gmail.com ",
      phone: "0773415862/0758650973",
      image: allan,
    },
      {
        name: "Mabonga Lasito",
        position: "Examination Officer",
        email: "examination@kti.ac.ug",
        phone: "0777271528/0701666952",
        image: lasito,
    },
    {
        name: "Ssebabi Sam",
        position: "Systems Administrator",
        email: "ssebabisam@gmail.com ",
        phone: "+256754436295",
        image: sam, 
    },
    {
        name: "Nakanyike Stella Grace",
        position: "Instructor, Catering section",
        email: "stellagracenakanyike2@gmail.com",
        phone: "0783926460",
        image: grace,  
    },
  
  ];
  return (

    <div className="container">
      <meta name="description" content="Meet our team at Your Company Name, including Mr. Sserwanja Asadu, our Senior Administrative Secretary, and other experienced professionals." />

      <h3 className="blue text-center mt-5 mb-5">
        STAFF <span className="red">MEMBERS</span>
      </h3>

      <div className="row">
        {team.map((member) => (
          <div className="col-md-3">
            <div className="team">
              <div className="team-img">
              <img src={member?.image} alt={`Photo of ${member?.name}, ${member?.position}`} />
              </div>
              <div className="team-content">
                <h2 className="capitalize">{member?.name}</h2>
                <h3 className="capitalize">{member?.position}</h3>
                <p>{member?.phone}</p>
                <h4 className="lowercase">{member?.email}</h4>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
