import React from "react";
import { Offcanvas, Navbar, Nav, Container } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default function MobileHeader({ page }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar bg="light" expand={false} className="mobile-header">
        <div className="container-fluid">
          <FontAwesomeIcon
            icon={faBars}
            onClick={handleShow}
            fontSize="25px"
            style={{ cursor: "pointer", paddingLeft: "10px" }}
          />

          <Navbar.Brand href="/">
            <img src="images/logo.png" alt="..." />
          </Navbar.Brand>
        </div>
      </Navbar>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="start"
        className="custom-offcanvas"
      >
        <Offcanvas.Header
          closeButton
          className="custom-header"
        ></Offcanvas.Header>
        <Offcanvas.Body>
          {/* Add a custom className for styling */}
          <Nav className="flex-column">
            {/* HOME PAGE */}
            <a
              href="/"
              onClick={handleClose}
              className={page === "home" ? "active" : ""}
            >
              Home
            </a>

            {/* ABOUT */}
            <a
              href="/about"
              onClick={handleClose}
              className={page === "about" ? "active" : ""}
            >
              About
            </a>

            {/* OUR COURSES */}
            <a
              href="/courses"
              onClick={handleClose}
              className={page === "courses" ? "active" : ""}
            >
              Our Courses
            </a>

            {/* ADMISSION */}
            <a
              href="/admission"
              onClick={handleClose}
              className={page === "admission" ? "active" : ""}
            >
              Admission
            </a>

            {/* FEES STRUCTURE */}
            <a
              href="fees_structure.pdf"
              target="_blank"
              className={page === "fees_structure" ? "active" : ""}
            >
              Fees Structure
            </a>

            {/* CONTACT */}
            <a
              href="/contact"
              onClick={handleClose}
              className={page === "contact" ? "active" : ""}
            >
              Contact
            </a>

            {/* PORTAL  */}
            <a
              href="https://portal.kti.ac.ug"
              onClick={handleClose}
            >
              Contact
            </a>

            {/* APPLY BUTTON */}
            <a href="https://admissions.kti.ac.ug" className="border-none">
              <button className="btn btn-warning rounded-pill px-4 w-100 mt-3">
                APPLY NOW
              </button>
            </a>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
