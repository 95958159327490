import React, { useState, useEffect } from "react";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <div>
      {isVisible && (
        <button
          onClick={scrollToTop}
          style={{
            position: "fixed",
            bottom: "20px",
            right: "30px",
            height: "45px",
            width: "45px",
            fontSize: "20px",
            zIndex: "1000",
            borderRadius: "50%",
            border: "none",
            cursor: "pointer",
            backgroundColor: "#f4b328",
            color: "#200769",
          }}
        >
          <i class="fa fa-chevron-up" aria-hidden="true"></i>
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;
