import { useEffect } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import Slider from "../components/slider";

export default function Homepage() {
  return (
    <div>
      <Header page={"home"} />

      <Slider />

      <div>
        {/* about  */}
        <div id="about" className="about mt-3 mt-md-5">
          <div className="container">
            <div className="about-box">
              <h2 className="red pt-4">
                Welcome To&nbsp;
                <strong className="blue">
                  Kyadondo Technical Institute (KTI)
                </strong>
              </h2>
              <p className="text-justify py-3 py-md-4">
                Welcome to Kyadondo Technical Institute (KTI), where we empower
                the next generation of innovators, technicians, and leaders. At
                KTI, we believe in providing a robust technical education that
                combines rigorous academic study with essential hands-on
                experience. Our programs are designed to meet the evolving needs
                of the industry, ensuring our graduates are not just job-ready
                but are equipped to drive change and shape the future. KTI
                offers a diverse range of courses in engineering, technology,
                and applied sciences, all taught by a dedicated faculty with
                real-world expertise. Our students engage in various
                extracurricular activities, community service projects, and
                leadership development programs, all aimed at nurturing
                well-rounded individuals who are ready to make a positive impact
                in their communities and beyond. Join us at Kyadondo Technical
                Institute and embark on a journey of discovery, learning, and
                personal growth. Shape your future in an environment that values
                tradition yet embraces innovation.
              </p>
            </div>
          </div>
        </div>

        <hr />

        {/* DIRECTOR'S MESSAGE */}
        <div id="about" className="about mt-1">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="about-box">
                  <h3 className="red uppercase">
                    <strong className="blue">Director's&nbsp; </strong>
                    Message
                  </h3>
                  <p className="text-justify py-3 py-md-4">
                    Education is undoubtedly one of the most important factors
                    that positively impact Human growth & the Growth and
                    Development of a country as well as the country's Human
                    Capital. It is indeed a powerful tool to combat the
                    cut-throat competition that man is faced with at every
                    juncture in life. But education doesn't mean to produce some
                    personnel for corporate houses. 'Skilling people' is a big
                    challenge for the whole world and nowhere more so than
                    Uganda. Instead of focusing on the high achievements of a
                    few, we at Kyadondo Technical Institute concentrate on
                    improving the educational ability of the whole. Our focus is
                    to bring about qualitative and value based education to
                    nurture our students to evolve as future global leaders. We
                    are in the noble endeavour of transforming lives of our
                    students through skills Development. We believe we are
                    artists at work and the students we churn out are our work
                    of art. I am proud of being the Director of this wonderful
                    Institution dedicated to the cause of transformation of our
                    Country. Come on let's give our best and make this
                    institution a modern temple of learning through our
                    diligence, devotion and dedication. Wishing you all the
                    best.
                    <br /> <br />
                    <span className="name-title">Mr. Sserwanja Asadu</span>
                    <br />
                    <span className="title">-Director</span>
                  </p>
                  <a href="/about">Learn More</a>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="about-box">
                  <figure>
                    <img src="images/director.png" alt="..." />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* our */}
        <div id="important" className="important">
          <div className="container">
            <div className="titlepage">
            <hr />

              <h2 className="red">
                Some <strong className="blue">Important Facts</strong>
              </h2>
            </div>
          </div>
          <div className="important_bg">
            <div className="container">
              <div className="row">
                <div className="col col-xs-12">
                  <div className="important_box">
                    <h3>1000+</h3>
                    <span>Students</span>
                  </div>
                </div>

                <div className="col col-xs-12">
                  <div className="important_box">
                    <h3>200+</h3>
                    <span>Faculty</span>
                  </div>
                </div>
                <div className="col col-xs-12">
                  <div className="important_box">
                    <h3>20+</h3>
                    <span>Programs</span>
                  </div>
                </div>

                <div className="col col-xs-12">
                  <div className="important_box">
                    <h3>10+</h3>
                    <span>Workshops</span>
                  </div>
                </div>

                <div className="col col-xs-12">
                  <div className="important_box">
                    <h3>12+</h3>
                    <span>Industry Partnerships</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end our */}
        {/* Courses */}
        <div id="courses" className="Courses">
          <div className="container-fluid padding_left3">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div className="box_bg">
                  <div className="box_bg_img">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="box_my">
                          <figure>
                            <img src="images/engineer.jpg" />
                          </figure>
                          <div className="overlay">
                            <h4 className="text-white py-1">Engineering</h4>
                            <p>
                              Our engineering courses deliver core principles
                              and hands-on experience with advanced engineering
                              solutions.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="box_my">
                          <figure>
                            <img src="images/cs.webp" />
                          </figure>
                          <div className="overlay">
                            <h4 className="text-white py-1">
                              Computer Sciences
                            </h4>

                            <p>
                              Our programs provide knowledge and in-depth
                              insights into the latest technologies and their
                              practical uses.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="box_my">
                          <figure>
                            <img src="images/accounting.webp" />
                          </figure>
                          <div className="overlay">
                            <h4 className="text-white py-1">
                              Accounting & Finance
                            </h4>
                            <p>
                              Our accounting and finance programs focus on
                              essential financial concepts and real-world
                              financial practices.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="box_my">
                          <figure>
                            <img src="images/fashion.jpg" />
                          </figure>
                          <div className="overlay">
                            <h5 className="text-white py-1">
                              Fashion & Design
                            </h5>
                            <p>
                              Our fashion and design courses blend creative
                              theory with practical skill in contemporary style
                              and trends.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 border_right">
                <div className="box_text px-3 px-md-0">
                  <div className="titlepage">
                    <h2 className="red">
                      Our <strong className="blue"> Courses</strong>
                    </h2>
                  </div>
                  <p>
                    Our educational offerings are designed to set you on the
                    path to professional success. Our diploma and certificate
                    courses cover key industries such as engineering,
                    technology, design, hospitality, business, and more, all
                    tailored to meet today's job market demands. Our courses
                    blend practical skills with essential knowledge, ensuring
                    you not only understand the theory but can also apply it in
                    real-world situations. With dedicated instructors and modern
                    facilities, we provide the tools and support necessary for
                    learning and innovation. Whether you're starting a new
                    career or advancing in your current one, KTI offers a
                    learning environment that fosters growth, creativity, and
                    the expertise needed to thrive in your chosen field. Join us
                    to build a solid foundation for your future.
                  </p>
                  <a href="/courses">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* WHY CHOOSE KTI */}
        <div className="make">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="titlepage">
                  <h2>
                    Why Choose
                    <strong className="white_colo"> KTI?</strong>
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 col-sm-12 text-white choose_us">
                <p>
                  <span className="yellow">State-of-the-Art Facilities: </span>
                  From our advanced computer labs to the fully-equipped
                  mechanical workshop, every corner of KTI is designed to
                  simulate real-world environments, giving you the practical
                  experience you need.
                </p>
                <hr />
                <p>
                  <span className="yellow">Experienced Faculty: </span>
                  Learn from the best—our faculty comprises industry veterans
                  and academic leaders passionate about mentoring the next
                  generation of technical experts.
                </p>
                <hr />

                <p>
                  <span className="yellow"> Hands-On Learning: </span>
                  At KTI, we believe in learning by doing. Our curriculum
                  emphasizes practical projects, internships, and workshops to
                  prepare you for the challenges of the industry.
                </p>
                <hr />

                <p>
                  <span className="yellow">Career Readiness: </span>
                  Our dedicated career services team ensures that you're not
                  just ready for your first job, but for a lifelong career, with
                  coaching, job fairs, and networking opportunities.
                </p>
                <hr />

                <p>
                  <span className="yellow">Community and Support: </span>
                  Join a diverse community of innovators and makers, with
                  student-led clubs, support groups, and campus events that make
                  KTI a place to grow both personally and professionally.
                </p>
                <hr />

                <a
                  href="https://admissions.kti.ac.ug"
                  className="btn btn-light rounded-pill mt-4 px-5 py-2"
                >
                  START YOUR JOURNEY NOW
                </a>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="make_img">
                  <figure>
                    <img src="images/why.png" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* learn */}
        <div id="learn" className="learn">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="titlepage">
                  <h2 className="red">
                    Learn <strong className="blue">the Practical way</strong>
                  </h2>
                  <span>
                    Dive into hands-on learning at Kyadondo Technical Institute!
                    Our programs blend theory with practical application,
                    ensuring you gain the skills needed for real-world success.
                    With state-of-the-art facilities and industry-savvy
                    instructors, we're here to turn your passion into a
                    profession. Join us and shape your future, practically!
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="learn_box">
                  <figure>
                    <iframe
                      width="100%"
                      height="500"
                      src="https://www.youtube.com/embed/C9VrGUiAAb4"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </figure>
                </div>
              </div>
            </div>

            {/* APPLY NOW */}
            <div className="text-center mt-5">
              <button className="btn btn-success rounded-pill px-5 py-2 w-50 apply_btn ">
                APPLY NOW
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
