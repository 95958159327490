import React from "react";
import Header from "../components/header";

export default function Courses() {
  // DIPLOMA COURSES
  const diplomaCourses = [
    {
      title: "National Diploma in Electrical Engineering (NDEE)",
      description:
        "This program covers the fundamentals of electrical engineering, including power generation, transmission, and distribution systems.",
      image:
        "images/engineer.jpg",
    },
    {
      title: "National Diploma in Civil Engineering (NDCE)",
      description:
        "Prepare for a career in civil engineering with courses in structural analysis, materials science, and construction management.",
      image:
        "https://studenthub.africa/app/uploads/careers/K8hpb3AumV_OpEEGdZ4l57tWeEklLJSP.jpg",
    },
    {
      title: "National Diploma in Automobile Engineering (NDAE)",
      description:
        "Dive into automotive engineering with a focus on vehicle design, performance testing, and manufacturing processes.",
      image:
        "https://studenthub.africa/app/uploads/careers/fZq_ZVmmDHB5S5BCUgU3K2iGZ4HzP-ap.jpg",
    },
    {
      title: "National Diploma in Water & Sanitation Engineering (NDWSE)",
      description:
        "Explore water resource management, wastewater treatment, and sustainable sanitation practices in this comprehensive program.",
      image:
        "https://images.squarespace-cdn.com/content/v1/5b60bd5e7c93271a80ef6d7a/1536672837664-I47CGC2P344FCR5I0V1R/1.jpg",
    },
    {
      title: "National Diploma in Architecture (NDAD)",
      description:
        "Learn the principles of architectural design, building technology, and project management in this dynamic program.",
      image:
        "https://miro.medium.com/v2/resize:fit:1400/1*3qQAnftGZO1L6jN7rdTnrg.jpeg",
    },
    {
      title: "National Diploma in ICT (NDICT)",
      description:
        "This program offers a deep dive into information and communication technologies, covering topics from network security to software development.",
      image: "https://www.newscraftgh.com/images/icons/3-1024x683.jpg",
    },
    {
      title: "National Diploma in Computer Science (NDCS)",
      description:
        "Gain a solid foundation in computer science with a curriculum that includes programming, algorithms, and data structures.",
      image:
        "https://www.usnews.com/object/image/0000016b-e1d5-d947-a56f-f1dd0e4d0000/computerscienceprofessional.jpg?update-time=1562862880129&size=responsive640",
    },
    {
      title: "National Diploma in Fashion & Garment Design (NDFD)",
      description:
        "Unleash your creativity in fashion and garment design, learning about textiles, pattern making, and fashion marketing.",
      image: "images/fashion_and_design.jpg",
    },
    {
      title:
        "National Diploma in Hotel Management & Institutional Catering (NDHIC)",
      description:
        "Prepare for a career in hospitality with courses in hotel operations, food service management, and customer service excellence.",
      image:
        "https://uenr.edu.gh/wp-content/uploads/2021/03/Department-of-Ecotourism.jpg.webp",
    },
    {
      title: "National Diploma in Tourism Management (NDTM)",
      description:
        "Explore the diverse world of tourism management, including tour operations, travel agency management, and sustainable tourism practices.",
      image:
        "https://atqnews.com/wp-content/uploads/2023/11/PRIVATE-SECTOR.jpg",
    },
    {
      title: "National Diploma in Cosmetology (NDCos)",
      description:
        "Delve into the world of beauty and wellness with comprehensive training in cosmetology, skincare, and makeup artistry.",
      image:
        "https://www.insurebodywork.com/u/2023/09/22175540/Cosmetology-Image-min.jpg",
    },
    {
      title: "National Diploma in Accounting (NDA)",
      description:
        "Master the principles of accounting, financial reporting, and auditing in this rigorous program designed for aspiring accountants.",
      image: "images/accounting.jpg",
    },
    {
      title: "National Diploma in Business Administration (NDBA)",
      description:
        "Develop key business skills in management, marketing, and entrepreneurship to thrive in today's dynamic business environment.",
      image: "images/business_admin.jpg",
    },
    {
      title: "National Diploma in Journalism (NDJ)",
      description:
        "Pursue a career in journalism with training in news writing, multimedia reporting, and ethical journalism practices.",
      image: "images/journalism.jpg",
    },
    {
      title: "Diploma in Nursery Teacher Education (ECDTE)",
      description:
        "Specialize in early childhood development and education, preparing to shape the minds of young learners in nursery settings.",
      image: "images/nursery.avif",
    },

    {
      title: "Diploma in Art & Industrial Design (AID)",
      description:
        "Explore the intersection of art and design, learning about industrial design principles, product development, and visual communication.",
      image:
        "https://www.southwark.ac.uk/images/Art_and_Design_news_story_640x480.jpg",
    },
  ];

  // CERTIFICATE COURSES
  const certificateCourses = [
    {
      title: "National Certificate in Electrical Installation Systems (NCES)",
      description:
        "Acquire practical skills in electrical installations, focusing on safety, wiring standards, and electrical systems design.",
      image: "https://douglaselectric.us/wp-content/uploads/2022/07/electrical-preventative-maintenance.jpg",
    },
    {
      title: "National Certificate in Welding & Metal Fabrication (NCWMF)",
      description:
        "Learn advanced welding and fabrication techniques to build and repair structures and components in various industries.",
      image: "https://www.fvtc.edu/Portals/0/POSImages/Programs/metal-fabrication-welding-lg.jpg",
    },

    {
      title: "National Certificate in Automotive Mechanics (NCAM)",
      description:
        "Develop expertise in automotive systems, diagnostics, and repair to excel in the fast-paced automotive industry.",
      image: "https://mechanicsforafrica.com/wp-content/uploads/2020/06/IMG_9134-copy-1201x800.jpg",
    },

    {
      title: "National Certificate in Plumbing & Pipe Fittings (NCPL)",
      description:
        "Master the installation and maintenance of plumbing systems, including water supply, drainage, and sanitary fixtures.",
      image: "https://www.hccs.edu/media/houston-community-college/continuing-education/images/SCE_PipefittingBanner",
    },

    {
      title: "National Certificate in ICT (NCICT)",
      description:
        "Get hands-on experience with the latest in information and communication technologies, from networking to cybersecurity.",
      image: "https://findit-resources.s3.amazonaws.com/forums/1656996397348.jpg",
    },
    {
      title: "National Certificate in Fashion & Garment Design (NCFD)",
      description:
        "Dive into the world of fashion with courses on design principles, garment construction, and fashion industry trends.",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQtPyKNLSuxzXYwSM29DfXVBtzTmYLwxdWJmnQPtyFoJO-frvGoOX5O_jE_p9Tyi9fjLz8&usqp=CAU",
    },
    {
      title: "National Certificate in Building & Construction (NCBC)",
      description:
        "Gain foundational knowledge in construction techniques, project management, and sustainable building practices.",
      image: "https://www.iiftbangalore.com/img/sections/courses/home-page-courses/PG-Diploma-fashion-design-boutique-management.webp",
    },
    {
      title:
        "National Certificate in Hotel Management & Institutional Catering (NCHIC)",
      description:
        "Learn the essentials of hotel management and catering, with a focus on hospitality, service excellence, and culinary skills.",
      image: "https://institutiontoday.com/wp-content/uploads/cwv-webp-images/2023/05/Catering-and-accommodation-course.jpg.webp",
    },
    {
      title: "National Certificate in Tourism Management (NCTM)",
      description:
        "Explore the principles of tourism management, including tour planning, hospitality, and cultural sensitivity.",
      image: "https://www.galaxyeduworld.com/storage/blogs/1597147900_5f328afc9e1d8_750_351.jpg",
    },
    {
      title: "National Certificate in Hair Dressing Technology (NCHDT)",
      description:
        "Develop professional skills in hair styling, cutting, coloring, and salon management.",
      image: "https://www.tandikainternational.com/wp-content/uploads/elementor/thumbs/HAIR-pghkf90jz7axe2030ggcm6rkdv2nv0n5umt8q0tjw8.webp",
    },
    {
      title: "National Certificate in Accounting (NCBA)",
      description:
        "Learn accounting fundamentals, financial analysis, and fiscal management to support businesses in financial decision-making.",
      image: "https://images.squarespace-cdn.com/content/v1/54023cabe4b00e19c7e8ac4a/1593709351146-TJ0VFSC8690B6A95AM1H/image-asset.jpeg",
    },
    {
      title: "National Certificate in Business Administration (NCBA)",
      description:
        "Get a broad understanding of business operations, from entrepreneurship to business strategy and organizational behavior.",
      image: "https://static.vecteezy.com/system/resources/thumbnails/023/882/076/small/check-display-with-blue-folders-documents-and-media-content-office-clerk-or-employee-search-and-index-files-file-management-data-storage-file-search-illustration-vector.jpg",
    },
    {
      title: "National Certificate in Journalism (NCJ)",
      description:
        "Hone your reporting, writing, and multimedia storytelling skills to make an impact in the media industry.",
      image: "https://old.smc.edu.ng/wp-content/uploads/2013/07/media-class.png",
    },
    {
      title: "Certificate in Nursery Teacher Education (ECDTE)",
      description:
        "Specialize in early childhood education techniques, fostering development and learning in young children.",
      image: "images/teacher.jpg",
    },
    {
      title: "Certificate in Art & Industrial Design (AID)",
      description:
        "Unlock your creative potential with courses in art theory, design principles, and hands-on design projects.",
      image: "https://blogassets.leverageedu.com/blog/wp-content/uploads/2019/11/23171928/Product-Design-Courses.png",
    },
  ];

  return (
    <>
      <Header page="courses" />
      <div className="container mt-4">
        
        <h1 className="text-center mb-4 py-2 blue ">
          Our <span className="red">Courses</span>
        </h1>

        <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">

          <li className="nav-item" role="presentation">
            <button
              className="nav-link active tab-btn"
              id="diploma-tab"
              data-bs-toggle="tab"
              data-bs-target="#diploma"
              type="button"
              role="tab"
              aria-controls="diploma"
              aria-selected="true"
            >
              Diploma Courses
            </button>
          </li>

          <li className="nav-item" role="presentation">
            <button
              className="nav-link tab-btn"
              id="certificate-tab"
              data-bs-toggle="tab"
              data-bs-target="#certificate"
              type="button"
              role="tab"
              aria-controls="certificate"
              aria-selected="false"
            >
              Certificate Courses
            </button>
          </li>
          
        </ul>


        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="diploma"
            role="tabpanel"
            aria-labelledby="diploma-tab"
          >
            <div className="row">
              {/* Diploma Courses */}
              {diplomaCourses.map((course, index) => (
                <div className="col-md-4 d-flex">
                  <div className="card mb-4 d-flex flex-column">
                    <img
                      src={course.image}
                      alt="..."
                      className="card-img-top"
                    />
                    <div className="card-body d-flex flex-column">
                      <h5 className="card-title">{course.title}</h5>
                      <p className="card-text mb-auto">{course.description}</p>
                    </div>

                    <div className="card-footer d-flex justify-content-center">
                      <a
                        href="https://admissions.kti.ac.ug"
                        className="btn btn-success w-100 btn-sm rounded-pill px-4 "
                      >
                        APPLY NOW
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="certificate"
            role="tabpanel"
            aria-labelledby="certificate-tab"
          >
            <div className="row">
              {/* Certificate Courses */}
              {certificateCourses.map((course, index) => (
                <div className="col-md-4 d-flex">
                  <div className="card mb-4 d-flex flex-column">
                    <img
                      src={course.image}
                      alt="..."
                      className="card-img-top"
                    />
                    <div className="card-body d-flex flex-column">
                      <h5 className="card-title">{course.title}</h5>
                      <p className="card-text mb-auto">{course.description}</p>
                    </div>

                    <div className="card-footer d-flex justify-content-center">
                      <a
                        href="https://admissions.kti.ac.ug"
                        className="btn btn-success w-100 btn-sm rounded-pill px-4 "
                      >
                        APPLY NOW
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
